@import "../../app.scss";

.home_container {
  row-gap: 7rem;
}

.home_content {
  grid-template-columns: 116px repeat(2, 1fr);
  padding-top: 5.5rem;
  column-gap: 2rem;
  align-items: center;
}

.home_social {
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home_social-icon {
  font-size: 1.25rem;
  color: map-get($colors, title-color);
}

.home_social-icon:hover {
  color: map-get($colors, title-color-dark);
}

.home_title {
  font-size: map-get($sizes, big);
  margin-bottom: map-get($margins, mb-0-25);
}

.home_subtitle {
  position: relative;
  font-size: map-get($sizes, h3);
  padding-left: 5.4rem;
  font-weight: map-get($font-weights, font-normal);
  margin-bottom: map-get($margins, mb-1);
}

.home_subtitle::before {
  content: "";
  position: absolute;
  width: 70px;
  height: 1px;
  background-color: map-get($colors, text-color);
  left: 0;
  top: 1rem;
}

.home_description {
  max-width: 400px;
  margin-bottom: 4rem;
  height: 3rem;
}

.home_img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9px rgba(255, 255, 255, 0.2);
  width: 300px;
  height: 300px;
  order: 1;
  justify-self: center;
  background: url("profile.png");
  animation: profile_animate 8s ease-in-out infinite 1s;
}

@keyframes profile_animate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.home_scroll {
  margin-left: 9.25rem;
  margin-top: 5rem;
}

.wheel {
  animation: scroll 2s ease infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(3.75rem);
  }
}

.home_scroll-name {
  color: map-get($colors, title-color);
  font-weight: map-get($font-weights,  font-medium);
  margin-right: map-get($margins, mb-0-25);
  margin-left: 2px;
}

.home_scroll-arrow {
  font-size: 1.25rem;
  color: map-get($colors, title-color);
}

/* =========== BREAKPOINTS ============== --> */

// Extra Small Devices (portrait phones)
@media screen and (max-width: 365px) {
  .home_img {
    width: 150px;
    height: 150px;
  }
}

// Medium Devices (tablets)
@media screen and (max-width: 768px) {
  .home_content {
    grid-template-columns: 0.5fr 3fr;
    padding-top: 3.5rem;
  }
  .home_img {
    order: initial;
    justify-self: initial;
  }
  .home_data {
    grid-column: 1/3;
  }
  .home_img {
    width: 200px;
    height: 200px;
    box-shadow: inset 0 0 0 6px rgba(255, 255, 255, 0.2);
  }
  .home_scroll {
    display: none;
  }

  .home_title {
    font-size: map-get($sizes, h1);
  }
}

// Large Devices (desktops)
@media screen and (max-width: 992px) {
  .home_content {
    grid-template-columns: 100px repeat (2, 1fr);
    column-gap: 1.25rem;
  }

  .home_subtitle {
    padding-left: 3.75rem;
    margin-bottom: map-get($margins, mb-1);
  }

  .home_subtitle::before {
    width: 42px;
    top: 0.8rem;
  }

  .home_description {
    max-width: initial;
    margin-bottom: map-get($margins, mb-2-5);
  }

  .home_img {
    width: 250px;
    height: 250px;
    padding-left: 50px;
    box-shadow: inset 0 0 0 8px rgba(255, 255, 255, 0.2);
  }

  .home_scroll {
    margin-left: 7.5rem;
  }
}
