@import "../../app.scss";

.qualification_container {
  max-width: 768px;
}

.qualification_tabs {
  display: flex;
  justify-content: center;
  margin-bottom: map-get($margins, mb-2);
}

.qualification_button {
  font-size: map-get($sizes, h3);
  font-weight: map-get($font-weights, medium);
  color: map-get($colors, title-color);
  margin: 0 map-get($margins, mb-1);
  cursor: pointer;
  border-radius: 0.5rem;
  padding: 0.25rem;
}

.qualification_button:hover {
  color: map-get($colors, container-color);
  background-color: map-get($colors, title-color);
}

.qualification_icon {
  font-size: 1.8rem;
  margin-right: map-get($margins, mb-0-25);
}

.qualification_active {
  background-color: map-get($colors, title-color);
  color: map-get($colors, container-color);
}

.qualification_sections {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
}

.qualification_content {
  display: none;
}

.qualification_content-active {
  display: block;
}

.qualification_data {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
}

.qualification_content_margin {
  margin-left: auto;
}

.qualification_title {
  font-size: map-get($sizes, normal);
  font-weight: map-get($font-weights, medium);
}

.qualification_subtitle {
  display: inline-block;
  font-size: map-get($sizes, small);
  margin-bottom: map-get($margins, mb-0-75);
}

.qualification_calendar {
  font-size: map-get($sizes, small);
  margin-bottom: 1.75rem;
}
.qualification_rounder {
  display: inline-block;
  width: 13px;
  height: 13px;
  background-color: map-get($colors, text-color);
  border-radius: 50%;
}

.qualification_line {
  display: block;
  width: 1px;
  height: 100%;
  background-color: map-get($colors, text-color);
  transform: translate(6px, -7px);
}

/* =========== BREAKPOINTS ============== --> */

// Small Devices
@media screen and (max-width: 350px) {
  .qualification_data {
    gap: 0.5rem;
  }
}

// Medium Devices
@media screen and (max-width: 576px) {
  .qualification_sections {
    grid-template-columns: initial;
  }
  .qualification_button {
    margin: 0 map-get($margins, mb-0-75);
  }
  .qualification_data {
    gap: 0.5rem;
    margin-left: 0.5rem;
  }
}

// Medium Devices
@media screen and (max-width: 768px) {
  .qualification_container {
    margin-left: map-get($margins, mb-1-5);
    margin-right: map-get($margins, mb-1-5);
  }
}

// Large Devices (desktops)
@media screen and (max-width: 992px) {
  .qualification_container {
    margin-right: auto;
    margin-left: auto;
  }
}
