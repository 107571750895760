@import "../../app.scss";

.contact_container {
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  column-gap: 6rem;
  padding-bottom: 3rem;
}

.contact_title {
  text-align: center;
  font-size: map-get($sizes, h3);
  font-weight: map-get($font-weights, medium);
  margin-bottom: map-get($margins, mb-1-5);
}

.contact_info {
  display: grid;
  row-gap: 1rem;
  grid-template-columns: 300px;
}

.contact_card {
  background-color: map-get($colors, container);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.75rem;
  text-align: center;
}

.contact_card-icon {
  color: map-get($colors, title-color);
  font-size: 2rem;
  margin-bottom: map-get($margins, mb-0-25);
}

.contact_card-title,
.contact_card-data {
  font-size: map-get($sizes, small);
}

.contact_card-title {
  font-weight: map-get($font-weights, medium);
}

.contact_card-data {
  display: block;
  margin-bottom: map-get($margins, mb-0-75);
}

.contact_button {
  font-size: map-get($sizes, small);
  color: map-get($colors, text-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.25rem;
}

.contact_button-icon {
  font-size: 1rem;
  transition: 0.3s;
}

.contact_button:hover .contact_button-icon {
  transform: translate(0.25rem);
}

.contact_form {
  width: 360px;
}

.contact_form-div {
  position: relative;
  margin-bottom: map-get($margins, mb-2);
  height: 4rem;
}

.contact_form-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: none;
  color: map-get($colors, text-color);
  outline: none;
  border-radius: 0.75rem;
  z-index: 1;
  padding-left: 20px;
}

.contact_form-tag {
  position: absolute;
  top: -0.75rem;
  left: 1.25rem;
  font-size: map-get($sizes, smaller);
  padding: 0.25rem;
  background-color: map-get($colors, body-color);
  z-index: 10;
}

.contact_form-area {
  height: 11rem;
}

input:focus::placeholder {
  color: transparent;
}

textarea:focus::placeholder {
  color: transparent;
}

textarea {
  padding-top: 1rem;
}

.contact_form-area textarea {
  resize: none;
}

.contact_button_send {
  margin-top: 1rem;
}

/* =========== BREAKPOINTS ============== --> */

// Extra Small Devices (portrait phones)
@media screen and (max-width: 350px) {
}

// Small Devices (landscape phones)
@media screen and (max-width: 576px) {
  .contact_container {
    grid-template-columns: 1fr;
  }
  .contact_form {
    width: 95%;
  }
  .contact_button_send {
    margin-left: 25%;
  }
}

// Medium Devices (tablets)
@media screen and (max-width: 768px) {
  .contact_container {
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
  .contact_info {
    justify-content: center;
  }
  .contact_form {
    margin: 0 auto;
  }
}

// Large Devices (desktops)
@media screen and (max-width: 992px) {
  .contact_container {
    column-gap: 3rem;
  }
}
