// Import Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/* =========== VARIABLES ============== --> */
$header-height: 3rem;
$colors: (
  hue: 0,
  sat: 0%,
  title-color: hsl(0, 0%, 25%),
  title-color-dark: hsl(0, 0%, 0%),
  text-color: hsl(0, 0%, 46%),
  body-color: hsl(0, 0%, 98%),
  container-color: #ffff,
);
$fonts: (
  body: "Poppins",
);
$sizes: (
  big: 2.5rem,
  h1: 2.25rem,
  h2: 1.5rem,
  h3: 1.25rem,
  normal: 1rem,
  small: 0.875rem,
  smaller: 0.813rem,
  tiny: 0.625rem,
);
$font-weights: (
  font-normal: 400,
  font-medium: 500,
  font-semi-bold: 600,
);
$margins: (
  mb-0-25: 0.25rem,
  mb-0-5: 0.5rem,
  mb-0-75: 0.75rem,
  mb-1: 1rem,
  mb-1-5: 1.5rem,
  mb-2: 2rem,
  mb-2-5: 2.5rem,
  mb-3: 3rem,
);
$z-indexes: (
  z-tooltip: 10,
  z-fixed: 100,
  z-modal: 1000,
);

// Media query for smaller screens
@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* =========== BASE ============== --> */

* {
  margin: 0;
  padding: 0;
  box-sizing: 0;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: map-get($fonts, body);
  font-size: map-get($sizes, normal);
}

body {
  background-color: map-get($colors, body-color);
  color: map-get($colors, text-color);
}

h1,
h2,
h3 {
  color: map-get($colors, title-color);
  font-weight: map-get($font-weights, font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* =========== THEMES ============== --> */

/* =========== CLASSES ============== --> */

.section {
  padding: 6rem 0 2rem;
}

.section_title {
  font-size: map-get($sizes, h1);
  color: map-get($colors, title-color);
}

.section_subtitle {
  display: block;
  font-size: map-get($sizes, small);
  margin-bottom: 4rem;
}

.section_title,
.section_subtitle {
  text-align: center;
}

.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.button {
  display: inline-block;
  background-color: map-get($colors, title-color);
  color: map-get($colors, container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: map-get($font-weights, medium);
}

.button:hover {
  background-color: map-get($colors, title-color-dark);
}

.button_icon {
  margin-left: map-get($margins, mb-0-5);
}

.button_flex {
  display: inline-flex;
  align-items: center;
}
/* =========== BREAKPOINTS ============== --> */

// Extra Small Devices (portrait phones)
@media screen and (max-width: 350px) {
  :root {
    --big-font-size: 2.25rem;
  }

  .container {
    margin-left: map-get($margins, mb-1);
    margin-right: map-get($margins, mb-1);
  }
}

// Small Devices (landscape phones)
@media screen and (max-width: 576px) {
}

// Medium Devices (tablets)
@media screen and (max-width: 768px) {
  body {
    margin: 0 0 $header-height 0;
  }

  .section {
    padding: 2rem 0 4rem;
  }
  .section_subtitle {
    margin-bottom: map-get($margins, mb-3);
  }
}

// Large Devices (desktops)
@media screen and (max-width: 992px) {
  .container {
    margin-left: map-get($margins, mb-1-5);
    margin-right: map-get($margins, mb-1-5);
  }
  .button {
    padding: 1rem 1.75rem;
  }
  .button_icon {
    width: 22px;
    height: 22px;
  }
}

// // Extra Large Devices (desktops)
// @media screen and (max-width: 1600px) {
//   .container {
//     margin-left: map-get($margins, mb-1-5);
//     margin-right: map-get($margins, mb-1-5);
//   }
//   // Styles for large devices
// }
