@import "../../app.scss";

.skills_container {
  grid-template-columns: repeat(3, 350px);
  column-gap: 2.5rem;
  justify-content: center;
}

.skills_content {
  background-color: map-get($colors, container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 2rem;
  border-radius: 1.25rem;
}

.skills_title {
  font-size: map-get($sizes, normal);
  font-weight: map-get($font-weights, medium);
  text-align: center;
  margin-bottom: map-get($margins, mb-1-5);
}

.skills_box {
  display: flex;
  justify-content: center;
  column-gap: 2.5rem;
}

.skills_group {
  display: grid;
  align-items: flex-start;
  row-gap: 1rem;
}
.skills_data {
  display: flex;
  column-gap: 0.5rem;
}

.skills .bx-badge-check {
  font-size: 1rem;
  color: map-get($colors, title-color);
}

.skills_name {
  font-size: map-get($sizes, normal);
  font-weight: map-get($font-weights, medium);
  line-height: 18px;
}

.skills_level {
  font-size: map-get($sizes, tiny);
}

/* =========== BREAKPOINTS ============== --> */

// Extra Small Devices (portrait phones)
@media screen and (max-width: 350px) {
  .skills_box {
    column-gap: 1.25rem;
  }

  .skills_name {
    font-size: map-get($sizes, small);
  }
  .skills_content {
    padding: auto;
    margin-left: 2.5rem;
  }
}

// Small Devices (landscape phones)
@media screen and (max-width: 576px) {
  .skills_box {
    column-gap: 1.25rem;
  }
  .skills_container {
    grid-template-columns: 1fr;
  }
  .skills .skills_content {
    padding: 2rem 4rem;
  }
  .skills_name {
    font-size: map-get($sizes, small);
  }
}

// Large Devices (desktops)
@media screen and (max-width: 992px) {
  .skills_container {
    grid-template-columns: max-content;
    row-gap: 2rem;
  }

  .skills_content {
    padding: 2rem 4rem;
  }
}
