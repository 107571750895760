@import "../../app.scss";

.work_filters {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.75rem;
  margin-bottom: map-get($margins, mb-2);
}

.work_item {
  color: map-get($colors, title-color);
  padding: 0.25rem 0.75rem;
  font-weight: map-get($font-weights, medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
  cursor: pointer;
}

.work_item:hover {
  background-color: map-get($colors, title-color);
  color: map-get($colors, container-color);
}

.work_container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.work_card {
  background-color: map-get($colors, container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
}

.work_img {
  width: 295px;
  border-radius: 1rem;
  margin-bottom: map-get($margins, mb-1);
}

.work_title {
  font-size: map-get($sizes, normal);
  font-weight: map-get($font-weights, medium);
  margin-bottom: map-get($margins, mb-0-5);
}

.work_button {
  color: map-get($colors, text-color);
  font-size: map-get($sizes, small);
  display: flex;

  align-items: center;
  column-gap: 0.25rem;
}

.work_button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.work_button:hover .work_button-icon {
  transform: translateX(0.25rem);
}

/* active work */
.active-work {
  background-color: map-get($colors, title-color);
  color: map-get($colors, container-color);
}

/* =========== BREAKPOINTS ============== --> */

// Large Devices (desktops)
@media screen and (max-width: 992px) {
  .work_container {
    gap: 1.25rem;
  }
  .work_card {
    padding: 1rem;
  }

  .work_img {
    margin-bottom: 0.75rem;
  }
  .work_title {
    margin-bottom: 0.25rem;
  }
}

// Medium Devices
@media screen and (max-width: 768px) {
  .work_container {
    grid-template-columns: max-content;
  }
}

@media screen and (max-width: 576px) {
  .work_container {
    grid-template-columns: 1fr;
  }

  .work_img {
    width: 100%;
  }
}

// Small Devices
@media screen and (max-width: 350px) {
  .work_item {
    font-size: map-get($sizes, small);
  }
  .work_filters {
    column-gap: 0.25rem;
  }
}
