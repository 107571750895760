@import "../../app.scss";

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-indexes, z-fixed);
  background-color: map-get($colors, body-color);
}

.nav {
  height: calc($header-height + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
}

.nav_logo,
.nav_toggle {
  color: map-get($colors, title-color);
  font-weight: map-get($font-weights, medium);
  font-size: map-get($sizes, h4);
}

.nav_list {
  display: flex;
  column-gap: 2rem;
}

.nav_link {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: map-get($sizes, small);
  color: map-get($colors, title-color);
  font-weight: map-get($font-weights, font-medium);
  transition: 0.3s;
}

.nav_icon,
.nav_close,
.nav_toggle {
  display: none;
}

.menu_hover {
  border-bottom: 1px solid transparent;
  transition: border-color 0.2s ease-in;
}
.active_link,
.menu_hover:hover {
  border-color: map-get($colors, title-color);
}

/* Change background header */

.scroll-header {
  box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.2);
}

/* =========== BREAKPOINTS ============== --> */

// Medium Devices
@media screen and (max-width: 768px) {
  .header {
    top: initial;
    bottom: 0;
  }

  .nav {
    height: $header-height;
  }

  .nav_menu {
    position: fixed;
    bottom: -100%;
    left: 0;
    background-color: map-get($colors, body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: 0.3s;
    width: calc(100% - 3rem);
  }

  /* Show Menu */

  .show_menu {
    bottom: 0;
  }

  .nav_icon {
    font-size: 1.2rem;
  }

  .nav_close {
    position: absolute;
    right: 1.3rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: map-get($colors, title-color);
  }

  .nav_close:hover {
    color: map-get($colors, title-color-dark);
  }

  .nav_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .nav_toggle {
    font-size: 1.1rem;
    cursor: pointer;
  }

  .nav_icon,
  .nav_close,
  .nav_toggle {
    display: block;
  }
}

// Small Devices
@media screen and (max-width: 350px) {
  .nav_menu {
    padding: 2rem 0.25rem 4rem;
  }

  .nav_list {
    column-gap: 0;
  }
}
